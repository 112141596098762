import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthUserAccessService {
  authCodeFlowConfig: any;
  url!: string;
  baseUrl = 'api/sso/';

  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtHelper: JwtHelperService
  ) {}

  getAuthUserAccess(): Observable<any> {
    return this.http.post('auth/userAccess', {});
  }

  postSessionId(portal?: string) {
    const portalParam = portal ? portal : 'IP';
    return this.http.post<any>(this.baseUrl + portalParam + '/ivecoPayB2C', {});
  }

  postCreateUser() {
    const token = sessionStorage.getItem('id_token');
    return this.http.post<any>('/api/b2c/createUser', { token });
  }

  getToken(portal?: string): Observable<any> {
    const portalParam = portal ? portal : 'IP';
    // it's the sso/token of BO
    return this.http.get<any>(this.baseUrl + portalParam + '/token');
  }

  // set cookie as expired
  postLogout(portal?: string) {
    const portalParam = portal ? portal : 'IP';
    return this.http.post<any>(this.baseUrl + portalParam + '/logout', {});
  }

  // check if user is authenticated and with permissions, used on general guard for private pages
  async checkAuthentication(): Promise<boolean> {
    const token = sessionStorage.getItem('id_token');

    if (token) {
      const decoded: any = jwtDecode(token);

      sessionStorage.setItem('email', decoded.sub);
      sessionStorage.setItem('userName', decoded.name + ' ' + decoded.surname);

      if (!decoded || (decoded && (!decoded.exp || !decoded.sub))) {
        return false;
      }

      if (this.jwtHelper.isTokenExpired(token)) {
        return false;
      }

      const data: any = await firstValueFrom(this.getAuthUserAccess());

      if (!data || data.length === 0) {
        /* adfs dice si ma utente non è presente in directx database allora forzo un logout*/
        this.router.navigate(['/logout']);
        return false;
      }

      if (data.approvalStatus === 'PENDING') {
        this.router.navigate(['/approvalPending']);
      }
      return true;
    }

    return false;
  }
}
